@import '../../layout/layout.scss';

.Item {
  padding: 8px;

  .Item__link {
    font-size: 14px;
    color: $blue-500;
    padding: 8px 0;
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }
  }

  @include breakpoint($lg) {
    min-width: 12%;
  }

  @include breakpoint($md) {
    min-width: 20%;
  }

  @include breakpoint($sm) {
    min-width: 33.3%;
  }

  @include breakpoint($xs) {
    min-width: 50%;
  }
}
