@import '../../layout/layout.scss';

.LegalSection {
  margin: 0 auto;

  .LegalSection__content {
    margin-top: 50px;
    border-top: 1px solid rgba($blue, 0.5);
  }

  .LegalSection__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .LegalSection__col {
    margin: 88px -15px 0;
    max-width: 50%;
    width: 100%;

    &:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }

    @include breakpoint($md) {
      margin-top: 60px;
      max-width: 100%;
    }
  }

  .TextBlock {
    max-width: 756px;
    padding: 0 15px;
  }
}