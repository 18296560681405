@import '../../layout/layout.scss';

  .Auth-social__wrapper {
    margin-top: 50px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      height: 1px;
      width: 100%;
      background-color: $gray-500;
    }
  }

  .Auth-social__label {
    @extend %flex-center;
    position: relative;
    z-index: 1;
    height: 76px;
    width: 76px;
    border: 1px solid $gray-500;
    border-radius: 50%;
    background-color: $white;
    font-size: 32px;
    line-height: 16px;
    font-weight: $font-weight-bold;
    color: $gray-text;

    span {
      margin-top: -8px;
    }
  }