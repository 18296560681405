@import '../../layout/layout.scss';

.FAQSection__row {
  border-top: 1px solid rgba($blue, 0.5);

  .TextBlock {
    max-width: 866px;
    margin: 0 auto;
    padding: 48px 15px 56px;
  }
}