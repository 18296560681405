@import '../../layout/layout.scss';

.FitersSection {
  min-width: 275px;
  margin-right: 50px;

  @include breakpoint($sm) {
    min-width: 200px;
    margin-right: 20px;
  }

  @include breakpoint($xs) {
    min-width: 100%;
  }

  .FitersSection__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: $font-weight-bold;
    color: $gray-text;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    padding: 15px 0;
    margin-top: 50px;
  }

  .FitersSection__clear {
    margin-top: 50px;
    text-align: center;

    .Btn {
      min-width: 200px;
    }
  }

  .Radio__row {
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoint($xs) {
      flex-direction: row;
    }
  }

  .Radio__wrapper {
    margin: 10px 0;
  }
}