@import '../../layout/layout.scss';

.HowItWorksSection__row {
  margin-top: 160px; 
  display: flex;

  &:nth-child(1) {
    margin-top: 100px; 

    @include breakpoint($sm) {
      margin-top: 70px; 
    }
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @include breakpoint($sm) {
    flex-wrap: wrap;
    margin-top: 90px; 
  }

  .HowItWorksSection__col {
    display: flex;
    align-items: center;

    &.col-img {
      min-width: 54%;

      @include breakpoint($sm) {
        order: 1;
      }
    }

    @include breakpoint($sm) {
      order: -1;
    }
  }

  .HowItWorksSection__img {
    width: 100%;
  }

  .TextBlock {
    position: relative;
    bottom: 65px;
    padding-top: 0;

    @include breakpoint($md) {
      bottom: 0px;
      border-bottom: 0;
    }
  }
}