@import '../../layout/layout.scss';

.autocomplete-dropdown-container {
  position: absolute;
  background-color: $white;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba($black, 0.2);
  max-width: 650px;
  z-index: 3;
  font-size: 15px;
  line-height: 16px;
  color: $gray-text;
  font-weight: normal;
  display: none;

  &.visible {
    display: block;
  }

  .suggestion-item,
  .suggestion-item--active {
    font-size: 15px;
    line-height: 16px;
    color: $gray-text;
    margin: 5px 0;
    padding: 10px 0px;
    width: 100%;
    cursor: pointer;

    &:hover, &.selected {
      color: $green;
    }
  }

  .suggestion-item--active {
    color: $green;
  }
}