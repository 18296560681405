@import '../../../layout/layout.scss';

.BtnGoogleLogin {
  @extend %flex-center;
  background-color: #F7F7F7 !important;
  border-radius: 28px !important;
  padding: 10px 20px 11px !important;
  width: 318px;
  min-height: 55px;
  border: 0 !important;
  box-shadow: none !important;
  margin: 20px 7px 0;
  opacity: 1 !important;
  transition-duration: $duration;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba($black, 0.16) !important;
  }

  &:focus{
    outline: none;
  }

  @include breakpoint($xs) {
    max-width: 260px;
  }
  
  span {
    @extend %flex-center;
    font-size: 15px;
    line-height: 20px;
    padding: 0 !important;
  }

  img {
    height: 34px;
    width: 34px;
    padding: 0;
    margin-right: 14px;
  }
}

.BtnLogin__wrapper {
  margin: 20px -7px 0;
  display: flex;
  justify-content: center;

  @include breakpoint($xs) {
    flex-wrap: wrap;
  }
}