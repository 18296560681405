@import '../../layout/layout.scss';

.Advantage {
  position: relative;
  margin-top: 30px;
  padding: 20px;
  text-align: center;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: block;
    height: 68px;
    width: 1px;
    background-color: $gray-200;
    opacity: 0.6;

    @include breakpoint($sm) {
      top: 0;
      transform: translateY(0%);
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 68px;
    }
  }

  &:first-child::before {
    display: none;
  }

  &:first-child {
    @include breakpoint($sm) {
      margin-top: 0px;
    }
  }

  @include breakpoint($sm) {
    padding-top: 40px;
  }

  .Advantage__img {
    height: 50px;
  }

  .Advantage__title {
    margin-top: 28px;
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    font-size: 26px;
    line-height: 32px;
    color: $blue-500;
    letter-spacing: -0.7px;

    @include breakpoint($sm) {
      margin-top: 18px;
    }
  }

  .Advantage__text {
    margin-top: 4px;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.35px;
  }

  .Advantage__link {
    margin-top: 28px;
    font-size: 20px;
    line-height: 26px;
    font-weight: $font-weight-medium;
    color: $green;
    text-decoration: underline;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }

    @include breakpoint($sm) {
      margin-top: 18px;
    }
  }
}