@import '../../layout/layout.scss';

.Contact {
  @extend %flex-center;
  flex-direction: column;
  position: relative;
  min-height: 100px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: block;
    height: 100px;
    width: 1px;
    background-color: $blue;
    opacity: 0.6;

    @include breakpoint($sm) {
      top: 0;
      transform: translateY(0%);
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 100px;
    }
  }

  &:first-child {
    @include breakpoint($sm) {
      margin-top: 0px;
    }
  }

  @include breakpoint($sm) {
    padding-top: 40px;
  }

  .Contact__title {
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    font-size: 20px;
    line-height: 26px;
    color: $green;
  }

  .Contact__text {
    margin-top: 5px;
    font-size: 22px;
    line-height: 28px;
    color: $gray-500;
    display: block;

    &[href]:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}

.Contact__wrapper {
  margin-top: 68px;
  width: 100%;
  max-width: 400px;

  &:first-child .Contact::before {
    display: none;
  }

  @include breakpoint($sm) {
    margin-top: 40px;
  }
}