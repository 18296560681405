@import '../../layout/layout.scss';

.Subheading__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint($sm) {
    flex-direction: column;
  }
}