// ############ Colors ############

$white:     #ffffff;
$gray-100:  #F2F2F2;
$gray-200:  #CCCCCC;
$gray-300:  #A3A3A3;
$gray-400:  #6A6A6A;
$gray-500:  #707070; 
$gray-text: #383838;
$black:     #000000;

$red:       #cc1c34;

$green:     #02BC29;
$green-600: #029621;
$blue-100:  #E4EBED;
$blue-500:  #002E6D;
$blue:      #122237;
