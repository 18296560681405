// ############ Variables ############

// Html 
$default-font-family:   $font-secondary;
$default-font-size:     15px;
$default-line-height:   1;
$default-font-weight:   normal;
$default-font-style:    normal;
$default-color:         $gray-text;
$default-bg:            $white;

$font-weight-light:     300;
$font-weight-medium:    500;
$font-weight-bold:      700;
$duration:              0.15s;