@import '../../layout/layout.scss';

.FAQSection {
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;

  .FAQSection__content {
    margin-top: 64px;
  }
}