@import '../../layout/layout.scss';

input, textarea, .Select {
  display: block;
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
  background-color: $white;
  margin-top: 12px;
  padding: 18px 20px;
  height: auto;
  font-family: $font-secondary;
  font-size: 13px;
  line-height: 16px;
  color: $gray-text;
  transition-duration: $duration;
  border: 1px solid $gray-400;
  border-radius: 28px;
 
  &:active, &:focus {
    outline: none;
    background-color: $white;
  }

  &.invalid {
    border-color: $red;
  }

  &.w-100 {
    max-width: 100%;
  }
    
  &.Input_with-icon {
    padding-left: 42px;
  }

  @include breakpoint($sm) {
    max-width: 100%;
  }

  @include placeholder {
    color: $gray-400;
  }
}

.Textarea {
  border-radius: 10px;
  height: 116px;
  resize: none;
  min-width: 100%;
  max-width: 100%;
}

label {
  position: relative;
  font-family: $font-secondary;
  font-weight: $font-weight-bold;
  font-size: 13px;
  line-height: 18px;
  color: $gray-text;
  padding: 0 23px;
  display: inline-block;
  width: 100%;

  &.w-50 {
    max-width: 50%;

    @include breakpoint($sm) {
      max-width: 100%;
    }
  }

  @include breakpoint($md) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.Label-with-icon {

  .icon, i {
    position: absolute;
    left: 36px;
    top: 46px;
    font-size: 20px;

    @include breakpoint($md) {
      left: 20px;
    }
  }
}

.Invalid-messege {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  line-height: 1;
  font-weight: $default-font-weight;
  text-align: right;
  color: $red;
  padding: 0 20px;
}

.Input__row {
  display: flex;
  margin: 0px -8px 0;

  &:first-child {
    margin-top: 20px;
  }

  @include breakpoint($sm) {
    flex-wrap: wrap;
  }

  .Input__group {
    position: relative;
    margin: 0px 8px;
    width: 100%;

    input {
      margin-top: 20px;
    }
  }
}

.InputWithLabel__row {
  display: flex; 
  margin: 14px -23px 0;

  @include breakpoint($sm) {
    flex-wrap: wrap;
    margin: 0px -8px 0;
  }
}

.btn:focus, .btn:active {
  box-shadow: none !important;
}