@import '../../layout/layout.scss';

.Header {
  background-color: $blue;
  color: $white;

  .Header__top-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0 16px;

    @include breakpoint($sm) {
      flex-direction: column;
    }

    .Header__left-col, .Header__right-col {
      position: relative;
      margin: -6px -8px 0;
      display: flex;
      align-items: center;

      @include breakpoint($sm) {
        margin-top: 15px;
      }
    }

    .Header__center-col{
      @include breakpoint($sm) {
        order: -1;
      }
    }

    .Header__logo {
      display: block;
      height: 88px;

      @include breakpoint($lg) {
        height: 60px;
      }

      @include breakpoint($sm) {
        height: 45px;
        order: -1;
      }

      img {
        height: 100%;
      }
    }
  }

  .Header__bottom-part {
    border-top: 1px solid rgba($white, 0.13);

    .HeaderList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1160px;
      width: 100%;
      margin: 0 auto;
      padding: 6px 20px 6px 0;

      &.show {
        @include breakpoint($md) {
          display: flex;
          padding-right: 0;
        }
      }

      @include breakpoint($lg) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      @include breakpoint($md) {
        flex-direction: row;
        display: none;
      }
    }

    .Header__toggler {
      display: none;
      margin: 10px auto;
      padding: 4px 12px;
      cursor: pointer;

      @include breakpoint($md) {
        display: inline-block;
      }

      .icon {
        font-size: 28px;
        color: $white;
      }
    }

    .Header__note {
      position: absolute;
      top: 16px;
      right: 15px;

      .icon {
        font-size: 26px;
        color: $white;
      }
    }
  }
}