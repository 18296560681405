@import '../../layout/layout.scss';

.AboutSection__row {
  margin-top: 140px;
  display: flex;

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @include breakpoint($md) {
    margin-top: 90px; 
  }

  @include breakpoint($sm) {
    flex-wrap: wrap;
  }

  .AboutSection__col {
    display: flex;
    align-items: center;

    &.col-img {
      min-width: 54%;

      @include breakpoint($sm) {
        margin-top: 30px;
        order: 1;
      }
    }
  }

  .AboutSection__img {
    width: 100%;
  }
  
  .TextBlock {
    @include breakpoint($md) {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}