@import '../../layout/layout.scss';

.FooterList {
  margin: 0 10px;

  @include breakpoint($lg) {
    margin-top: 30px;
  }

  @include breakpoint($md) {
    min-width: 45%;
  }

  @include breakpoint($sm) {
    margin-left: 0;
    margin-right: 0;
    min-width: 100%;
    text-align: center;
  }
}

.FooterItemList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 170px;
  max-width: 326px;
  flex-direction: column;
  max-height: 180px;
  margin-top: 6px;

  &.FooterList__lg {
    min-width: 300px;

    @include breakpoint($md) {
      min-width: 50%;
    }
  }

  &.FooterList__networks {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 4px;

    @include breakpoint($lg) {
      min-width: 100px;
    }
  }

  @include breakpoint($sm) {
    min-width: 100%;
    max-width: 100%;
  }
}