@import '../../layout/layout.scss';

.ContactUsForm {
  margin: 0 auto;
  max-width: 1050px;

  .Btn__wrapper {
    margin-top: 70px;
  
    @include breakpoint($sm) {
      margin-top: 40px;
    }
  }
}

.ContactUsForm__wrapper {
  background-color: $gray-100;
  padding-bottom: 120px;
  margin-bottom: -120px;

  @include breakpoint($sm) {
    margin-bottom: -80px;
  }
}