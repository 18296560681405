@import '../../layout/layout.scss';

.Radio__row {
  margin-top: 35px;
  display: flex;
  align-items: center;

  @include breakpoint($sm) {
    flex-wrap: wrap;
  }

  label {
    width: auto;
    padding-left: 0;
    padding-right: 16px;

    @include breakpoint($sm) {
      width: 100%;
    }
  }
}

.Radio__wrapper {
  position: relative;
  display: flex;
  align-items: center;

  @include breakpoint($sm) {
    margin: 10px 0;
  }

  .Radio__input {
    display: none;
  }

  .Radio__label {
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    padding-left: 34px;
    padding-right: 0;
    margin-right: 26px;
    cursor: pointer;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid $gray-200;
      box-sizing: border-box;
    }

    &::after {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      display: inline-block;
      opacity: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $green;
      transition-duration: $duration;
    }

    &.invalid::before {
      border-color: $red;
    }
  }

  .Radio__label.checked::after {
    opacity: 1;
  }
}