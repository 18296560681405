@import '../../layout/layout.scss';

.SetUpSection {
  margin: 0 auto;
  max-width: 650px;

  .InputWithLabel__row:first-child {
    margin-top: 50px;
  }
  
  .Radio__block {
    margin-bottom: 34px;
  }

  .Btn__wrapper {
    margin: 55px -8px 0;

    .Btn {
      margin: 20px 8px 0;
    }
  }
}