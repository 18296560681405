@import '../../layout/layout.scss';

.HeaderItem {
  margin: 0 8px;

  @include breakpoint($lg) {
    min-width: 18%;
  }

  @include breakpoint($md) {
    min-width: 30%;
  }

  @include breakpoint($sm) {
    min-width: 46%;
  }

  .HeaderLink {
    font-size: 15px;
    line-height: 18px;
    color: $white;
    font-weight: $font-weight-bold;
    padding: 14px 8px;
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }
  }
}