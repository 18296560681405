@import '../../layout/layout.scss';

.SignInSection {
  margin: 0 auto;
  max-width: 650px;
  text-align: center;

  .Btn__wrapper {
    margin-top: 25px;
  }
}