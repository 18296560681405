@import '../../layout/layout.scss';

.FooterHeading {
  font-size: 15px;
  line-height: 34px;
  font-weight: $font-weight-medium;
  color: $white;
  padding: 0 0 14px 20px;
  border-bottom: 1px solid rgba($white, 0.3);

  @include breakpoint($sm) {
    padding-left: 0px;
  }
}