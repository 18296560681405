@import '../../layout/layout.scss';

.NoResultSection {
  padding-top: 50px;
  text-align: center;

  .NoResultSection__title {
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    color: $blue;
    font-size: 72px;
    line-height: 108px;
    text-transform: uppercase;

    @include breakpoint($sm) {
      font-size: 48px;
      line-height: 60px;
    }
  }

  img {
    margin-top: 12px;
    height: 370px;
  }
}