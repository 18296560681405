@import '../../layout/layout.scss';

.SignUpSection {
  margin: 0 auto;
  max-width: 650px;
  text-align: center;

  .Input__row:first-child {
    margin-top: 40px;
  }

  .Checkbox__row {
    margin-top: 36px;
    display: flex;
  }

  .Checkbox__wrapper {
    margin-right: 28px;
    padding-top: 4px;

    @include breakpoint($sm) {
      margin-right: 18px;
    }

    .Checkbox__label {
      @extend %flex-center;
      padding: 0;
      height: 24px;
      width: 24px;
      border: 1px solid $gray-400;
      transition-duration: $duration;
      cursor: pointer;

      &.invalid {
        border-color: $red;
      }

      @include breakpoint($sm) {
        margin-top: 0px;
      }
    }

    .Checkbox__input {
      display: none;
    }

    .icon {
      opacity: 0;
      font-size: 10px;
      color: $green;
      transition-duration: $duration;
    }

    .Checkbox__input:checked ~ .Checkbox__label {
      .icon {
        opacity: 1;
      }
    }
  }

  .Description {
    text-align: left;
    font-size: 13px;
    line-height: 25px;
  }
}