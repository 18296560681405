
@import '../../layout/layout.scss';

.ImageList {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-top: 32px;

  @include breakpoint($md) {
    flex-wrap: wrap;
  }
}