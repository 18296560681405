@import './layout.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
    outline: none;
}

html {
    height: 100%;
}

body {
    height: 100%;
    position: relative;
    font-family: $default-font-family !important;
    font-size: $default-font-size;
    line-height: $default-line-height;
    font-style: $default-font-style;
    font-weight: $default-font-weight;
    color: $default-color;
    background-color: $default-bg;
}

.Layout {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

.Container {
    position: relative;
    max-width: 1680px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @include breakpoint($lg) {
        max-width: 960px;
    }

    @include breakpoint($md) {
        max-width: 720px;
    }

    @include breakpoint($sm) {
        max-width: 540px;
    }
    
    @include breakpoint($xs) {
        max-width: 100%;
    }
}

.offset-top {
    padding-top: 100px;

    @include breakpoint($sm) {
        padding-top: 80px;
    }
}

.hidden {
    display: none;
}

a {
    font-family: $font-secondary;
    text-decoration: none;
    transition-duration: $duration;

    &:hover {
        text-decoration: none;
    }
}