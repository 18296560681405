@import '../../layout/layout.scss';

.Subheading {

  h1, h2, h3, h4, h5, h6 {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -1px;

    @include breakpoint($md) {
      font-size: 28px;
    }

    @include breakpoint($sm) {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
  }
}