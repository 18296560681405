@import '../../layout/layout.scss';

.CardSectionResults {
  width: 100%;
  
  .CardList {
    justify-content: flex-start;

    @include breakpoint($xs) {
      justify-content: center;
    }
  }

  .CardList {
    margin-top: 25px;
  }
}