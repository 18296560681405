@import '../../layout/layout.scss';

.ImageList__col.first {
  display: flex;
  flex-wrap: wrap;
  min-width: 66.6%;

  @include breakpoint($md) {
    min-width: 100%;
  }

  .Image:first-child {
    min-width: 100%;
  }
}

.ImageList__col.second {
  min-width: 33.3%;

  @include breakpoint($md) {
    min-width: 100%;
  }

  .Image {
    min-width: 100%;
    height: 100%;

    @include breakpoint($md) {
      height: 310px;
    }
  }
}

.Image {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  min-width: 50%;
  max-width: 33.3%;
  height: 420px;
  margin: 0;
  overflow: hidden;
  float: left;

  &:hover {
    .Image__img {
      transform: scale(1.05);
    }
  }

  @include breakpoint($lg) {
    height: 310px;
  }

  @include breakpoint($xs) {
    min-width: 100%;
  }

  .Image__img {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
    transition-duration: 0.4s;
  }

  .Image__body {
    background: linear-gradient(to bottom, rgba($black,0) 0%, rgba($black,0.7) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 30px 42px;

    @include breakpoint($lg) {
      padding: 20px 32px;
    }

    @include breakpoint($sm) {
      padding: 20px;
    }
  }

  .Image__title {
    font-family: $font-primary;
    font-size: 44px;
    line-height: 54px;
    font-weight: $font-weight-bold;
    color: $white;
    letter-spacing: -1px;
    display: block;

    @include breakpoint($lg) {
      font-size: 40px;
    }

    @include breakpoint($sm) {
      font-size: 36px;
    }
  }

  .Image__text {
    margin-top: 5px;
    background-color: $green;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 16px;
    font-weight: $font-weight-bold;
    color: $white;
    border-radius: 17px;
    display: inline-block;
    padding: 10px 15px 8px;

    @include breakpoint($sm) {
      padding: 8px 12px 6px;
    }
  }
}