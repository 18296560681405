@import '../../layout/layout.scss';

.SearchForm {

  input, textarea, .Select {
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background-color: $gray-100;
    margin: 0;
    padding: 9px 28px;
    height: auto;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 16px;
    color: $gray-text;
    transition-duration: $duration;
    border: 0;
    min-height: 70px;
    border-radius: 0;

    @include breakpoint($md) {
      min-height: 60px;
    }

    &:active, &:focus {
      outline: none;
      background-color: $white;
    }
    
    &.Input_with-icon {
      padding-left: 55px;
    }

    @include placeholder {
      color: $gray-text;
    }
  }

  .Input__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    border-left: 1px solid #ccc;

    &:first-child {
      border-left: 0;
      max-width: 265px;
      min-width: 265px;
  
      @include breakpoint($md) {
        max-width: 100%;
      }
    }

    @include breakpoint($md) {
      border-left: 0;
      border-bottom: 1px solid $gray-200;
    }

    .icon {
      font-size: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 26px;
      z-index: 2;
    }
  }

  .btn:focus, .btn:active {
    box-shadow: none !important;
  }
}