@import '../../layout/layout.scss';

.Recaptcha__wrapper {
  margin-top: 32px;
  border-top: 1px solid $gray-500;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  .Recaptcha__description {
    font-size: 13px;
    line-height: 25px;
    min-width: 100%;
    text-align: center;

    &.invalid {
      color: $red;
    }
  }

  .Recaptcha {
    margin-top: 10px;
  }
}