@import '../../layout/layout.scss';

.AdvantageSection {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include breakpoint($sm) {
    flex-wrap: wrap;
  }
} 