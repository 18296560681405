@import '../../layout/layout.scss';

.ProductCard {
  display: flex;
  border-radius: 28px;
  border: 1px solid $gray-200;

  @include breakpoint($md) {
    flex-wrap: wrap;
  }

  .ProductCard__left-col {
    padding: 55px;
    width: 100%;

    @include breakpoint($md) {
      margin: 0 auto;
      padding: 30px;
      text-align: center;
    }

    @include breakpoint($xs) {
      padding: 30px 15px;
    }

    .ProductCard__img {
      @extend %flex-center;
      flex-direction: column;
      overflow: hidden;
      height: 540px;
      width: 100%;
      max-width: 540px;
      box-sizing: border-box;
      border-radius: 19px;

      @include breakpoint($md) {
        margin: 0 auto;
        height: 448px;
        max-width: 500px;
      }

      @include breakpoint($xs) {
        height: 258px;
      }

      img {
        width: 100%;
        height: auto;
      } 
    }
  }

  .ProductCard__right-col {
    background-color: $gray-100;
    border-radius: 28px;
    width: 100%;
    padding: 55px 50px;

    @include breakpoint($md) {
      padding: 30px;
    }

    @include breakpoint($xs) {
      padding: 30px 15px;
    }
  }

  .ProductCard__title {
    font-family: $font-primary;
    font-size: 32px;
    line-height: 40px;
    font-weight: $font-weight-bold;
    color: $blue;
    letter-spacing: -0.8px;
  }

  .ProductCard__desc {
    margin-top: 18px;
    line-height: 28px;
  }

  .ProductCard__list {
    margin: 30px 0 0;
    padding-left: 0px;
    list-style-position: inside;
    line-height: 32px;
  }

  .ProductCard__certified {
    margin-top: 50px;
    display: flex;
    align-items: center;
    color: $green;
    font-weight: $font-weight-medium;

    img {
      margin-right: 8px;
    }
  }

  .ProductCard__price {
    margin-top: 50px;
    font-family: $font-primary;
    font-size: 26px;
    font-weight: $font-weight-bold;
    color: $blue;
    line-height: 30px;

    .ProductCard__type-price {
      font-size: 18px;
    }
  }
}