@import '../../layout/layout.scss';

.TextBlock {
  padding: 52px 12% 64px 14%;

  &.border-bottom {
    border-bottom: 1px solid $blue;
  }

  @include breakpoint($sm) {
    padding-left: 0;
    padding-right: 0;
  }

  .TextBlock__heading {
    font-family: $font-primary;
    font-size: 20px;
    line-height: 25px;
    font-weight: $font-weight-bold;
    color: $gray-text;
  }

  .TextBlock__content {
    font-size: 14px;
    line-height: 19px;
    color: $gray-500; 

    p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 19px;
      color: $gray-500; 
    }
  }
}