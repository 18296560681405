@import '../../layout/layout.scss';

.BtnSearch {
  position: relative;
  background-color: $green;
  border-radius: 35px;
  padding: 23px 20px 21px;
  font-size: 20px;
  font-family: $font-secondary;
  font-weight: $font-weight-medium;
  color: $white;
  line-height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  min-width: 200px;
  margin-left: -15px;
  transition-duration: $duration;
  cursor: pointer;

  &:hover {
    background-color: $green-600
  }

  .icon {
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: -2px;
  }
  
  @include breakpoint($md) {
    height: 60px;
    width: 100%;
    border-radius: 0 0 35px 35px;
    margin-left: 0px;
  }
}