

p {
    margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-primary;
    font-style: normal;
    font-weight: $font-weight-bold;
    color: $gray-text;
    margin: 0;
    line-height: 1;
}

h1 {
    font-size: 48px;
    line-height: 61px;
    color: $white;
    letter-spacing: -1px;
    text-align: center;
    text-shadow: 0px 3px 6px rgba($black, 0.16);

    @include breakpoint($lg) {
        font-size: 46px;
    }

    @include breakpoint($md) {
        font-size: 42px;
    }

    @include breakpoint($sm) {
        font-size: 36px;
        line-height: 40px;
    }
}

.Text {
    margin-top: 6px;
    font-size: 16px;
    line-height: 20px;
    color: $gray-text;
}

.Link_green {
    color: $green; 
}

.Link_blue {
    display: inline-block;
    margin-top: 24px;
    font-size: 13px;
    line-height: 20px;
    color: $blue;
    text-decoration: underline;
    padding: 0 10px;

    &:hover {
        text-decoration: none;
    }
}

.Link__wrapper {
    text-align: center;
}