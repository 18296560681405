@import '../../layout/layout.scss';

.ConfirmAccountSection {
  margin: 0 auto;
  max-width: 650px;
  text-align: center;

  .Input__row:first-child {
    margin-top: 30px;
  }
}