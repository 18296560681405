@import '../../layout/layout.scss';

.PopularItemsList__wraaper {
  background-color: $blue-100;
  padding: 5px 0 4px;
}

.PopularItemsList__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -8px;

  @include breakpoint($lg) {
    flex-wrap: wrap;
  }

  .PopularItemsList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    @include breakpoint($lg) {
      justify-content: flex-start;
    }

    &.show {
      @include breakpoint($md) {
        display: flex;
      }
    }

    @include breakpoint($md) {
      display: none;
    }
  }

  .Item__subheading {
    font-weight: $font-weight-bold;
    font-size: 14px;
    color: $blue-500;
    padding: 8px 0;
    margin: 8px;
    white-space: nowrap;

    &.desc {
      @include breakpoint($md) {
        display: none;
      }
    }

    &.mob {
      display: none;

      @include breakpoint($md) {
        display: inline-block;
      }
    }
  }

  .Item__toggler {
    display: none;
    margin: 8px 0;
    padding: 4px 12px;
    cursor: pointer;

    @include breakpoint($md) {
      display: inline-block;
    }

    .icon {
      font-size: 28px;
      color: $blue-500;
    }
  }
}