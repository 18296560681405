@import '../../layout/layout.scss';

.IntroSection__wrapper {
  background-image: url(/images/bg-intro.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;

  .Container {
    min-height: 628px;
    display: flex;
    align-items: center;
  
    @include breakpoint($lg) {
      min-height: 520px;
    }
  }

  .IntroSection {
    margin: 0 auto;
    max-width: 1170px;
  }
}