@import '../../layout/layout.scss';

.Select {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 34px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 26px;
    display: block;
    border: 5px solid transparent;
    border-bottom-width: 0;
    border-top-color: $gray-text;
  }

  &.Select_with-icon {
    padding-left: 72px;
  }

  &.invalid {
    border-color: $red;
  }
}

.SelectMenu {
  position: absolute;
  top: 90%;
  right: 0;
  background-color: $white;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba($black, 0.2);
  z-index: 3;
  display: none;

  &.open {
    display: block;
  }

  @include breakpoint($md) {
    width: 100%;
  }

  .SelectItem {
    font-size: 15px;
    line-height: 16px;
    color: $gray-text;
    margin: 5px 0;
    padding: 10px 0px;
    width: 100%;
    cursor: pointer;

    &:hover, &.selected {
      color: $green;
    }

    &.d-block {
      display: block;
    }
  }
}

.Select__wrapper {
  position: relative;
  margin: 0 23px;
  width: 100%;

  @include breakpoint($md) {
    margin-left: 8px;
    margin-right: 8px;
  }
  
  label {
    padding: 0;
  }
}