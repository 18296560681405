@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?5v9xbu') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?5v9xbu') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?5v9xbu##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-close-sm {
  &:before {
    content: $icon-close-sm; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-arrow-top {
  &:before {
    content: $icon-arrow-top; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-star-filled {
  &:before {
    content: $icon-star-filled; 
  }
}
.icon-right-arrow {
  &:before {
    content: $icon-right-arrow; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-marker {
  &:before {
    content: $icon-marker; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}

