@import '../../layout/layout.scss';

.Footer__wrapper {
  margin-top: 120px;
  background-color: $blue;
  border-top: 6px solid $green-600;
  padding: 50px 0 70px;

  @include breakpoint($sm) {
    margin-top: 80px;
  }
}

.Footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 70px;

  @include breakpoint($lg) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include breakpoint($sm) {
    padding-right: 0px;
  }

  .Logo__wrapper {
    max-width: 122px;
    margin-top: 8px;
    margin-right: 10px;

    @include breakpoint($lg) {
      margin: 0;
    }

    @include breakpoint($sm) {
      max-width: 90px;
    }

    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  .Footer__col {
    display: flex;
    justify-content: space-between;
    max-width: 1410px;
    width: 100%;

    @include breakpoint($md) {
      flex-wrap: wrap;
    }
  }
}