@import '../../layout/layout.scss';

.Btn {
  position: relative;
  background-color: $green;
  border-radius: 35px;
  padding: 23px 20px 21px;
  font-family: $font-secondary;
  font-size: 20px;
  font-weight: $font-weight-medium;
  color: $white;
  line-height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba($black,0.16);
  transition-duration: $duration;
  cursor: pointer;

  &:hover {
    background-color: $green-600
  }

  &:focus {
    outline: none;
  }

  &.primary {
    background-color: $green;

    &:hover {
      background-color: $green-600;
    }

    &:active {
      box-shadow: 0 0 0 0.2rem rgba($green-600,.5);
    }
  }

  &.secondary {
    background-color: rgba($white,.14);

    &:hover {
      background-color: rgba($white,.5);
    }

    &:active {
      box-shadow: 0 0 0 0.2rem rgba($gray-200,.5);
    }
  }

  &.thirdly {
    background-color: $blue;

    &:hover {
      background-color: rgba($blue,.9);
    }

    &:active {
      box-shadow: 0 0 0 0.2rem rgba($gray-200,.5);
    }
  }

  &.Btn-sm {
    margin: 0 8px;
    display: inline-flex;
    font-size: 14px;
    line-height: 1;
    border-radius: 19px;
    padding: 13px 20px 11px;
  }

  &.Btn-xs {
    margin: 0 8px;
    display: inline-flex;
    font-size: 22px;
    line-height: 1;
    border-radius: 19px;
    padding: 0;
    height: 38px;
    width: 42px;

    .icon-email {
      font-size: 16px;
    }
  }

  &.Btn-notice {

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: -3px;
      right: -4px;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 1px solid $blue;
      background-color: $green;
    }
  }

  &.BtnBasket {
    padding-left: 46px;
    padding-right: 13px;
  
    img {
      height: 21px;
      position: absolute;
      top: 50%;
      transform: translateY(-12px);
      left: 18px;
    }
  
    .icon {
      font-size: 12px;
      margin-left: 5px;
    }
  
    .Counter {
      color: $green;
    }

    .Btn__text {
      @include breakpoint($md) {
        display: none;
      }
    }
  }

  &.BtnDropdown {
    position: relative;
    justify-content: left;
    padding-right: 34px;
    min-width: 185px;
  
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-1px);
      right: 19px;
      display: block;
      border: 4px solid transparent;
      border-bottom-width: 0;
      border-top-color: $white;
    }

    &:hover {
      background-color: $green;
    }

    @include breakpoint($md) {
      min-width: 85px;
    }

    .Btn__icon {
      display: none;
      font-size: 22px;
      line-height: 14px;


      @include breakpoint($md) {
        display: block;
      }
    }

    .Btn__text {
      @include breakpoint($md) {
        display: none;
      }
    }
  }
}