@import '../../../layout/layout.scss';

.BtnFacebookLogin {
  @extend %flex-center;
  background-color: #4267B2;
  border-radius: 28px;
  padding: 13px 20px 13px;
  color: $white;
  font-weight: $font-weight-medium;
  font-size: 15px;
  line-height: 20px;
  border: 0;
  width: 100%;
  min-height: 55px;
  transition-duration: $duration;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba($black, 0.2);
  }

  &:focus{
    outline: none;
  }
  
  i {
    font-size: 28px;
    line-height: 1;
    margin-right: 18px;
    color: $white;
  }
}

.BtnFacebookLogin__wrapper {
  margin: 20px 7px 0;
  width: 318px;

  @include breakpoint($xs) {
    max-width: 260px;
  }
}