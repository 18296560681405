$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-profile: "\e90f";
$icon-bell: "\e90d";
$icon-email: "\e90e";
$icon-close-sm: "\e90a";
$icon-check: "\e90b";
$icon-close: "\e90c";
$icon-arrow-top: "\e909";
$icon-instagram: "\e908";
$icon-menu: "\e907";
$icon-star: "\e905";
$icon-star-filled: "\e906";
$icon-right-arrow: "\e900";
$icon-info: "\e901";
$icon-list: "\e902";
$icon-marker: "\e903";
$icon-search: "\e904";

