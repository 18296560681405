@import '../../layout/layout.scss';

.FooterItem {
  font-size: 15px;
  line-height: 19px;
  font-weight: $font-weight-medium;
  color: $white;
  opacity: 0.6;
  transition-duration: $duration;
  padding: 5px 10px 5px 20px;
  margin-top: 6px;
  margin-right: 45px;

  &:hover {
    opacity: 1;
  }

  &.FooterItem__round {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 52px;
    border: 4px solid $white;
    border-radius: 50%;
    padding: 0;
    margin: 15px 15px 0;

    @include breakpoint($lg) {
      margin-top: 10px;
    }

    .icon, .fab {
      font-size: 18px;
      transition-duration: $duration;
    }
  }

  &.go-top {
    position: absolute;
    top: 72px;
    right: 0;
    margin: 0;
    opacity: 1;

    @include breakpoint($lg) {
      top: 50px;
    }

    @include breakpoint($sm) {
      top: 20px;
    }
  }

  @include breakpoint($sm) {
    margin-right: 0;
    padding-left: 10px;
  }
}