@import '../../layout/layout.scss';

.SearchForm {
  margin-top: 14px;
  background-color: $gray-100;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  border-radius: 35px;
  box-shadow: 0px 3px 6px rgba($black, 0.48);

  @include breakpoint($md) {
    flex-wrap: wrap;
  }
}