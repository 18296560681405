@import '../../layout/layout.scss';

.Heading {
  text-align: center;

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-primary;
    font-size: 40px;
    line-height: 50px;
    font-weight: $font-weight-bold;
    letter-spacing: -1px;
    color: $blue;

    @include breakpoint($sm) {
      font-size: 32px;
      line-height: 36px;
    }
  }
}