@import '../../layout/layout.scss';

.SearchForm {
  .Select {
    
    &.SelectSearch {
      display: inline-flex;
      align-items: center;
      padding-left: 72px;
      padding-right: 34px;
      border-radius: 35px 0 0 35px; 
      min-width: 265px;
      cursor: pointer;

      @include breakpoint($md) {
        max-width: 100%;
        border-radius: 35px 35px 0 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 26px;
      display: block;
      border: 5px solid transparent;
      border-bottom-width: 0;
      border-top-color: $gray-text;
    }
  }

  .SelectMenu {
    position: absolute;
    top: 110%;
    right: 0;
    background-color: $gray-100;
    padding: 8px 0;
    width: 85%;
    border-radius: 20px;
    box-shadow: 0px 3px 6px rgba($black, 0.48);
    z-index: 3;
    display: none;

    &.open {
      display: block;
    }

    @include breakpoint($md) {
      width: 100%;
    }

    .SelectItem {
      font-size: 14px;
      line-height: 16px;
      padding: 10px 15px 10px 28px;
      width: 100%;
      cursor: pointer;

      &:hover, &.selected {
        color: $green;
      }
    }
  }
}