@import '../../layout/layout.scss';

.Placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  margin-top: -46px;
  margin-left: -46px;
  background-color: $green;
  border-radius: 50%;

  .icon {
    font-size: 46px;
    color: $white;
  }
}