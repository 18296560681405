@import '../../layout/layout.scss';

.Btn.BtnSubmit {
  border-radius: 28px;
  padding: 18px 20px 17px;
  min-width: 260px;
  font-size: 15px;
  line-height: 20px;
  border: 0;
  box-shadow: none;
}

.Btn__wrapper {
  text-align: center;
  margin-top: 30px;

  @include breakpoint($sm) {
    margin-top: 30px;
  }
}