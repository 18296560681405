@import '../../layout/layout.scss';

.BtnViewMore {
  font-family: $font-primary;
  font-weight: $font-weight-bold;
  color: $blue-500;
  margin-left: 20px;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }

  @include breakpoint($sm) {
    margin-top: 15px;
  }

  .icon {
    font-size: 12px;
    margin-left: 5px;
  }
}