@import '../../layout/layout.scss';

.CTASection__wrapper {
  background-image: url(/images/bg-CTA.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px 0 60px;

  &.margins {
    margin-top: 120px;
    margin-bottom: -120px;

    @include breakpoint($sm) {
      margin-top: 80px;
      margin-bottom: -80px;
    }
  }

  @include breakpoint($sm) {
    margin-top: 0px;
    padding: 80px 0 60px;
  }
}

.CTASection {
  display: flex;

  .CTASection__col { 
    width: 100%;
    max-width: 40%;
    padding-left: 10px;

    &:last-child {
      max-width: 60%;

      @include breakpoint($md) {
        display: none;
      }
    }

    @include breakpoint($md) {
      max-width: 100%;
      text-align: center;
    }

    h1, h2, h3, h4, h5, h6 {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.6px; 
      color: $white;
      margin-top: 6px;

      @include breakpoint($lg) {
        font-size: 50px;
        line-height: 60px;
      }

      @include breakpoint($sm) {
        font-size: 40px;
        line-height: 46px;
      }
    }

    .CTASection__img {
      max-width: 784px;
      width: 100%;

      @include breakpoint($md) {
        display: none;
      }
    }

    .Btn {
      margin-top: 14px; 
      min-width: 280px;
    }
  }
}