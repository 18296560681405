@import '../../layout/layout.scss';

.CardList {
  margin: 0 -23px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint($md) {
    margin-left: -15px;
    margin-right: -15px;
  }
}