@import '../../layout/layout.scss';

.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  border: 1px solid rgba($black,.125);
  min-width: 246px;
  width: 100%;
  max-width: 290px;
  margin: 25px 23px 0;
  border-radius: 20px;
  overflow: hidden;

  @include breakpoint($md) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .Card__img {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 315px;
    border-radius: 20px 20px 0 0;
    transition-duration: 0.4s;

    &:hover {
      transform: scale(1.05);
    }

    @include breakpoint($sm) {
      height: 270px;
    }
  }

  .Card__body {
    padding: 34px 18px 20px;

    @include breakpoint($sm) {
      padding: 20px 15px 15px;
    }
  }

  .Card__row {
    position: relative;
    padding-right: 20px;
  }

  .Card__title {
    line-height: 20px;
    font-weight: $font-weight-medium;
    color: $gray-text;
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }
  }

  .Card__text {
      line-height: 20px;
      font-weight: $font-weight-light;
      color: $gray-300;
  }

  .Card__checkbox {
    position: absolute;
    top: 3px;
    right: -1px;
    cursor: pointer;

    .icon {
      font-size: 17px;
      color: $green;
    }
    
    .Checkbox-icon {
      display: block;
    }

    .Checkbox-icon-chosen {
      display: none;
    }

    .Check-input {
      display: none;
    }

    .Check-label {
      padding: 0;
      cursor: pointer;
    }

    .Check-input:checked ~ .Check-label {
      .Checkbox-icon {
        display: none;
      }

      .Checkbox-icon-chosen {
        display: block;
      }
    }
  }

  .Card__price {
    margin-top: 12px;
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    font-size: 22px;
    line-height: 28px;
  }

  .Card__unit {
    margin-left: 8px;
    font-size: 15px;
  }
}