@import '../../layout/layout.scss';

.Modal__wrapper {
  position: relative;
  min-height: 414px;
}

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  background-color: $white;
  padding-top: 66px;

  &.ModalSuccess {
    .ModalSuccess__content {
      display: block;
    }
  }

  &.ModalError {
    .ModalError__content {
      display: block;
    }
  }

  .ModalSuccess__content,
  .ModalError__content {
    display: none;
    height: 100%;
  }


  .Modal__icon__wrapper {
    text-align: center;
  }

  .Modal__icon {
    font-size: 22px;
    font-weight: 700;
    color: $blue;
    display: inline-block;
    transition-duration: $duration;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .Modal__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }

  .Modal__img {
    height: 94px;
  }

  .Modal__text {
    font-size: 21px;
    line-height: 1;
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    color: $blue;
    letter-spacing: -0.5px;
    margin-top: 12px;
  }
}