@import '../../layout/layout.scss';

.DropdownMenu {
  position: absolute;
  top: 147%;
  left: 0;
  background-color: $white;
  padding: 5px 20px;
  width: 100%;
  min-width: 235px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba($black, 0.2);
  z-index: 3;
  display: none;

  &.open {
    display: block;
  }

  .DropdownItem {
    font-size: 15px;
    line-height: 16px;
    color: $gray-text;
    margin: 5px 0;
    padding: 12px 0px;
    width: 100%;
    display: block;
    cursor: pointer;

    &:hover, &.selected {
      color: $green;
    }
  }
}

.Dropdown__wrapper {
  position: relative;
  display: inline-block;

  @include breakpoint($md) {
    position: static;
  }
}